/**
 * This script is used to submit the localization form when the country selector is changed.
 * This way, the user can change the localization settings without having to submit the form manually.
 * It should be loaded on every page that contains the localization form snippet.
 */

const form = document.getElementById('localization_form');
const countrySelector = document.getElementById('country-selector');

if (form instanceof HTMLFormElement) {
  countrySelector?.addEventListener('change', () => form.submit());
}
