import { JSX } from "preact";
import { useState } from "preact/hooks";
import isEmail from 'validator/es/lib/isEmail';

export default NewsletterForm;

/**
 * Preact newsletter form component.
 * Takes a users email and signs them up for the newsletter
 * @returns  the newsletter component
 */
function NewsletterForm({ invalidEmailErr, newsletterReqErr, successMsg, onFormSubmit }: NewsletterProps) {
  const [email, setEmail] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [reqFailed, setReqFailed] = useState<boolean>(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)

  const onSubmit = (e: JSX.TargetedEvent<HTMLFormElement, Event>) => {
    e.preventDefault();
    const isValid = (isEmail as (email: string) => boolean)(email);
    if(!isValid) {
      setIsValidEmail(false)
      setTimeout(() => { setIsValidEmail(true) }, 10000);
    } else {
      onFormSubmit(email)
         .then(() => {
          setShowSuccessMsg(true);
          setIsValidEmail(true)
        })
        .catch(() => {
          setReqFailed(true);
          setTimeout(() => { setReqFailed(false) }, 10000);
        })
    }
  }

  return (
    <>
     <form onSubmit={e => onSubmit(e)} className="flex flex-col gap-y-1">
        <div className="flex w-full justify-center gap-4" >
          { showSuccessMsg ? (
            <span className="text-2xl font-bold text-crisp-white" >{successMsg}</span>
          ) : (
            <div className="relative w-full" >
              <input
                className="w-full rounded-full py-3 pl-4 pr-10"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail((e?.target as HTMLInputElement).value || '')}
                autoComplete="email"
                aria-label="email"
              />
              <button type="submit" className="absolute right-4 top-1/2 -translate-y-1/2" >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
                  <path d="M1.05566 5.25C0.64145 5.25 0.305664 5.58579 0.305664 6C0.305664 6.41421 0.641451 6.75 1.05566 6.75L1.05566 5.25ZM20.3987 6.53033C20.6916 6.23744 20.6916 5.76256 20.3987 5.46967L15.6258 0.696698C15.3329 0.403805 14.858 0.403805 14.5651 0.696698C14.2722 0.989592 14.2722 1.46447 14.5651 1.75736L18.8077 6L14.5651 10.2426C14.2722 10.5355 14.2722 11.0104 14.5651 11.3033C14.858 11.5962 15.3329 11.5962 15.6258 11.3033L20.3987 6.53033ZM1.05566 6.75L19.8684 6.75L19.8684 5.25L1.05566 5.25L1.05566 6.75Z" fill="#042E80"/>
                </svg>
              </button>
            </div>
          )}
        </div>

        { reqFailed || !isValidEmail ? (
          <p className="ml-5 animate-fadeOut text-left text-lg font-bold text-salmon">
            { reqFailed ? newsletterReqErr : null}
            { !isValidEmail ? invalidEmailErr : null}
          </p>
        ) : null}
      </form>
    </>
  );
}

export type NewsletterProps = {
  invalidEmailErr: string,
  newsletterReqErr: string,
  successMsg: string,
  onFormSubmit: (email: string) => Promise<Response>
};

