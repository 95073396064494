import {startDrawer, endDrawer} from '@/components/drawers';
import {getFeatureFlag} from '@/feature-flags/feature-flags-store';

const navbarBurger = document.getElementById('navbar-burger');
navbarBurger?.addEventListener('click', () => startDrawer.open());

const cartIconButtons = document.querySelectorAll('[id*="cart-icon-button"]');
for (const button of cartIconButtons) {
  button.addEventListener('click', () => {
    const cartPageFlag = getFeatureFlag('cart-page');
    if (cartPageFlag === 'true') {
      window.location.href = '/cart';
    } else {
      endDrawer.open();
    }
  });
}
