import {onBeforeCheckout} from '@/cart/cart-store';
import type {Cart} from '@/cart/types';
import {CartSectionSettings} from '@/cart/types';
import ButtonLoadingIndicator from '@/components/ButtonLoadingIndicator';
import LoadingIndicator from '@/components/LoadingIndicator';
import PlaceholderLineItem from '@/components/PlaceholderLineItem';
import SummaryGrid from '@/components/SummaryGrid';
import DonationSelector from '@/components/buy-stack/DonationSelector';
import LineItemDetailLegacy from '@/components/cart/LineItemDetailLegacy';
import {getFeatureFlag} from '@/feature-flags/feature-flags-store';
import amex from '@/icons/amex.svg';
import lockIcon from '@/icons/lock-white.svg';
import mastercard from '@/icons/mastercard.svg';
import paypal from '@/icons/paypal.svg';
import visa from '@/icons/visa.svg';
import formatCurrency from '@/utilities/format-currency';
import {Plan} from '@rechargeapps/storefront-client';
import {useState} from 'preact/hooks';

export default CartSection;

/**
 * This component displays the cart contents and allows the user to
 * proceed to checkout. It is intended to be used as the contents of
 * the cart drawer.
 */
function CartSection({
  cart,
  onCheckout,
  onDonationUpdate,
  onQuantityUpdate,
  settings,
}: CartSectionProps) {
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const altCheckout = getFeatureFlag('checkout');

  const itemCount = cart?.item_count ?? 0;
  // setting total price to null triggers the loading state
  const totalPrice =
    cart?.total_price === -1
      ? null
      : formatCurrency((cart?.total_price ?? 0) / 100, cart?.currency);

  /**
   * Get the cart item count string. This will be used to display the
   * number of items in the cart.
   */
  const cartItemCount = (itemCount: number) => {
    return `${itemCount} Item${itemCount === 1 ? '' : 's'}`;
  };

  /**
   * Handle the checkout button being clicked. This will call the
   * onCheckout prop if it is defined, and will also set the loading
   * indicator to true.
   */
  const handleCheckout = (e: Event) => {
    e.preventDefault();
    setLoadingCheckout(true);
    onBeforeCheckout(() => setLoadingCheckout(false));
    if (onCheckout) void onCheckout();
  };

  return (
    <form
      className="flex h-full max-h-full flex-col"
      onSubmit={(e) => e.preventDefault()}
    >
      <p className="px-6 text-xl">
        {itemCount === 0 ? (
          settings.empty_cart_title
        ) : (
          <>
            {settings.cart_title}
            <span className="font-bold"> {cartItemCount(itemCount)}</span>
          </>
        )}
      </p>
      <hr className="mt-2 w-full" />
      <div
        className="-mr-6 grow overflow-auto pr-6"
        data-testid={`line-item-list-view`}
      >
        {cart?.items?.map((item, i) => {
          const line = i + 1;
          const isLoading = item.id <= 0;
          return (
            <>
              {isLoading ? (
                <PlaceholderLineItem key={item.id} />
              ) : (
                <LineItemDetailLegacy
                  key={i}
                  item={item}
                  currency={cart.currency}
                  onUpdateQuantity={(quantity) =>
                    onQuantityUpdate && void onQuantityUpdate(line, quantity)
                  }
                />
              )}
              {i < cart.items.length - 1 && <hr className="col-span-full" />}
            </>
          );
        })}
      </div>
      <div className="grid grid-cols-1 gap-y-4">
        <DonationSelector
          name="cart-donation-category"
          onUpdate={onDonationUpdate}
          settings={settings}
        />
        <SummaryGrid
          className="px-6"
          lines={[
            {
              label: settings.subtotal_line_label,
              value: totalPrice,
            },
            {
              label: settings.shipping_line_label,
              value: (
                <span className="text-primary-blue">
                  {settings.free_shipping}
                </span>
              ),
            },
          ]}
        />
        {!!cart?.item_count && (
          <>
            <ButtonLoadingIndicator
              className="px-6"
              button={
                <button
                  className="btn btn-lime btn-square w-full"
                  onClick={handleCheckout}
                >
                  {altCheckout ? (
                    <FeatureFlagCheckoutLabel />
                  ) : (
                    <span>{settings.checkout_button_label}</span>
                  )}
                </button>
              }
              fullWidth
              isLoading={loadingCheckout}
              loader={<LoadingIndicator color="logo-lime" />}
            />
            {altCheckout ? <FeatureFlagUnderCheckout /> : null}
          </>
        )}
      </div>
    </form>
  );
}

type CartSectionProps = {
  cart: Cart | null;
  onCheckout?: () => void | Promise<void>;
  onDonationUpdate?: (category: string) => void | Promise<void>;
  onQuantityUpdate?: (line: number, quantity: number) => void | Promise<void>;
  onSellingPlanUpdate?: (
    line: number,
    plan: Plan | null
  ) => void | Promise<void>;
  settings: CartSectionSettings;
};

const FeatureFlagCheckoutLabel = () => (
  <div className="flex items-center justify-center gap-x-2">
    <img className="h-6" src={lockIcon} alt="lock icon" />
    <div className="pt-1.5 leading-6">SECURE CHECKOUT</div>
  </div>
);

const FeatureFlagUnderCheckout = () => (
  <div className="grid grid-cols-1 px-6">
    <span className="text-center text-sm font-medium">
      Try Risk Free - 100% Money Back Guarantee
    </span>
    <div className="flex items-center justify-center gap-x-1">
      <img className="h-8" src={mastercard} alt="mastercard" />
      <img className="h-8" src={visa} alt="visa" />
      <img className="h-8" src={amex} alt="american express" />
      <img className="h-8" src={paypal} alt="paypal" />
    </div>
  </div>
);
